// import React from 'react';
// import { google, slack, atlassian, dropbox, shopify } from './imports';
import "./brand.css";

const Brand = () => (
  // <div className="gpt3__brand section__padding">
  //   <div>

  //     <img src={google} alt="google logo"/>
  //   </div>
  //   <div>
  //     <img src={slack} alt="slack title"/>
  //   </div>
  //   <div>
  //     <img src={atlassian} alt="atlasiiian brand"/>
  //   </div>
  //   <div>
  //     <img src={dropbox} alt="dropbox rep"/>
  //   </div>
  <>
    {/* <div className="gpt3__brand section__padding">
      <div>
        <img src={shopify} alt="shopify mute" style={{width:"50px"}} />
      </div>
    </div> */}
  </>
);

export default Brand;
